/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Fighter Pilot
		Date:		6th February 2019
		Author:		Edge Marketing Solutions

===========================================================================*/

	$color-black: rgb(0, 0, 0);
    $color-white: rgb(255, 255, 255);
    $color-red: rgb(181, 33, 33);
    $color-grey-dark: rgb(47, 47, 47);
    $color-grey-mid: rgb(74, 74, 74);

    $font-default: 'Barlow', sans-serif;
    $font-heading: 'Barlow Condensed', sans-serif;

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-grey-mid;
        font-family: $font-default;

        font-weight: 400;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;

        color: $color-red;
	}

        a:hover
        {
            text-decoration: underline;
            color: $color-red;
        }

    h1
    {
        margin: 0 0 2rem 0;

        font-family: $font-heading;
        font-style: italic;
        color: $color-red;
        font-size: 3.4rem;
    }

    h2
    {
        margin: 2rem 0;

        font-family: $font-heading;
        font-style: italic;
        color: $color-red;
        font-size: 3.0rem;
    }

    h3
    {
        margin: 2rem 0;

        font-family: $font-heading;
        font-style: italic;
        color: $color-red;
        font-size: 2.6rem;
    }

    h4
    {
        margin: 2rem 0;

        font-family: $font-heading;
        font-style: italic;
        color: $color-red;
        font-size: 2.2rem;
    }

    h5
    {
        margin: 2rem 0;

        font-family: $font-heading;
        font-style: italic;
        color: $color-red;
        font-size: 1.8rem;
    }

    h6
    {
        margin: 2rem 0 0.5rem 0;

        font-family: $font-heading;
        font-style: italic;
        color: $color-red;
        font-size: 1.4rem;
    }

    .tooltip-show
    {
        z-index: 10000;
        opacity: 1;
    }

    .btn
    {
        border-radius: 0;
        border: none;
    }

        .btn-primary
        {
            background-color: $color-red;
        }

            .btn-primary:hover,
            .btn-primary:not(:disabled):not(.disabled):active,
            .btn-primary:not(:disabled):not(.disabled).active,
            .show > .btn-primary.dropdown-toggle
            {
                background-color: $color-black;
            }

        .btn-secondary
        {
            background-color: $color-black;
        }

    .dont-break-out{
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
    }

    .breadcrumb
    {
        background: transparent;
    }

    img
    {
        max-width: 100%;
    }

    img.img-lightbox
    {
        cursor: zoom-in;
    }

    /* Large desktop */
    @media (min-width: 1200px) {

    }

    /* Desktop */
    @media (min-width: 992px) and (max-width: 1199px) {

    }

    /* Portait Table and Mobile */
    @media (max-width: 991px) {
        h1
        {
            font-size: 3rem;
        }

        h2
        {
            font-size: 2.6rem;
        }

        h3
        {
            font-size: 2.2rem;
        }

        h4
        {
            font-size: 1.8rem;
        }

        h5
        {
            font-size: 1.4rem;
        }

        h6
        {
            font-size: 1.0rem;
        }
    }

    .fa-phone-custom
    {
        background-color: #b52121;
        color: white;
        padding: 8px;
        margin-bottom: 17px;
        font-size: 20px;
        position: relative;
    }

    /* Landscape phone to portrait tablet */
    @media (max-width: 767px) {
        .d-xs-none
        {
            display: none!important;
        }
    }

    /*=========================================================================*/

    /* [Header Template Styles] */

    .header
    {
        position: relative;
        z-index: 8000;
        width: 100%;
        padding: 0.5rem 2rem;

        background-color: $color-black;

        .phone
        {
            margin: 0 1rem;
            font-size: 2.4rem;
        }

        .h-logo
        {
            img
            {
                max-width: 190px;
            }
        }

    }

    /* Large desktop */
    @media (min-width: 1200px) {
        .header
        {
            position: absolute;
            top: 0;

            background-color: transparentize($color-black, 0.3);
        }
    }

    /* Desktop */
    @media (min-width: 992px) and (max-width: 1199px) {
        .header
        {
            position: relative;
            background-color: $color-black;
        }
    }

    /* Portrait tablet to landscape and desktop */
    @media (min-width: 768px) and (max-width: 991px) {

    }

    /* Landscape phone to portrait tablet */
    @media (max-width: 767px) {
        .header
        {
            padding: 1rem 0.5rem 1.5rem 0.5rem;

            text-align: center;

            .h-logo
            {
                img
                {
                    max-width: 300px;
                }
            }

            .phone
            {
                margin: 1rem 0;
                padding: 0.375rem 0;

                font-size: 16px;
            }
        }
    }

    /*=========================================================================*/

    /* [Navigation Template Styles] */

    .navbar-dark
    {
        padding: 0;

        .navbar-collapse
        {
            justify-content: flex-end;
        }

        .navbar-nav
        {
            align-items: center;

            .nav-link
            {
                padding: 0 0.6rem;

                color: $color-white;
                text-transform: uppercase;
                font-family: $font-heading;
                font-weight: 400;
            }

            .nav-link:hover
            {
                padding-bottom: 0.5rem;

                text-decoration: underline;
                color: $color-white;
            }

            .social
            {
                padding: 0 1rem;

                font-size: 1.8rem;
                color: rgb(49, 139, 203);
            }

            .btn-primary:hover
            {
                background-color: $color-white;

                color: $color-red;
            }

            .show > .nav-link,
            .active > .nav-link,
            .nav-link.show,
            .nav-link.active
            {
                color: white;
                text-decoration: underline;
            }

            .dropdown-menu
            {
                border-radius: 0;
                border: none;

                .dropdown-item
                {
                    padding: 0.25rem 0.5rem;

                    text-align: center;
                    white-space: normal;
                }

                .dropdown-item.active,
                .dropdown-item:active
                {
                    background-color: $color-red;
                }
            }
        }

        .navbar-toggler
        {
            width: 100%;
            padding: 1rem 0.5rem;

            border: 1px solid $color-white;
            border-radius: 0;

            color: $color-white;
        }
    }

    /* Desktop */
    @media (min-width: 768px) {

        .navbar-dark
        {
            .navbar-nav
            {
                .dropdown-menu
                {
                    left: 50%;
                    min-width: 200px;
                    transform: translateX(-50%);
                }
            }
        }
    }

    /* Portrait tablet to landscape and desktop */
    @media (min-width: 768px) and (max-width: 991px) {
        .navbar-dark
        {
            .navbar-collapse
            {
                .navbar-nav
                {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    /* Landscape phone to portrait tablet */
    @media (max-width: 767px) {
        .navbar-dark {
            .navbar-nav {
                display: block;

                .nav-link {
                    padding: 0.8rem 0rem;
                    width: 100%;

                    border-bottom: 1px solid transparentize($color-white, 0.8);

                    font-size: 1.2rem;
                }
            }
        }
    }

    /*=========================================================================*/

    /* [Page Template Styles] */

    .page
    {
        width: 100%;
        padding: 2rem 0;

        .nav
        {
            h6
            {
                margin: 0 0 0.5rem 0;

                color: $color-black;
            }

            .nav-link
            {
                padding: 0.5rem 0;

                color: $color-black;
            }

            .nav-item.active .nav-link
            {
                color: $color-red;
            }

            .nav-link:hover
            {
                text-decoration: underline;
            }
        }
    }

    .swiper-container
    {
        width: 100%;
        min-height: 250px;

        .swiper-pagination-bullet
        {
            background: $color-red;
        }
    }

    .subpage-banner
    {
        width: 100%;
        position: relative;
        z-index: 1;

        text-align: center;

        h1
        {
            position: absolute;
            top: 50%;
            width: 100%;
            margin: 0;
            padding: 0;
            transform: translateY(-50%);

            color: $color-white;
            text-align: center;
            text-transform: uppercase;
        }

        img
        {
            width: 100%;
        }
    }

    #blog-listings-container
    {
        h3
        {
            margin: 0;
            font-size: 32px;

            small
            {
                font-size: 20px;
            }
        }
    }

    .subpage-banner:before
    {
        position: absolute;
        content: ' ';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        background-color: transparentize($color-black, 0.5);
    }

    .subpage-banner-aircraft:before
    {
        display: none;
    }

    .listing-extra-services
    {
        display: flex;
        flex-flow: column;
        align-items: stretch;
        width: 100%;
    }

    .concierge
    {
        width: 100%;
        display: flex;
        position: relative;
        z-index: 1000;
        padding: 21px 0;

        background-color: $color-black;

        color: $color-white;
        text-align: center;
        text-transform: uppercase;
        font-family: $font-heading;
        font-style: italic;
        font-size: 1.5rem;
        font-weight: 300;

        .container .row .col-lg-2
        {
            padding-bottom: 1.3rem;
            padding-top: 1.3rem;
        }

        .btn-primary
        {
            font-family: $font-default;
            font-style: normal;
        }

        .btn-primary:hover
        {
            background-color: $color-white;

            color: $color-red;
        }

        img
        {
            position: absolute;
            bottom: -21px;
            left: 15%;
            width: 280px;
        }
    }

    .gallery-hover
    {
        position: relative;
    }

    .gallery-link
    {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .hover-caption
    {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.75);
        z-index: 10;
        display: none;
        overflow: hidden;

        .caption
        {
            position: absolute;
            color: white;
            text-align: center;
            padding: 15%;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .youtube-full-width
    {
        .youtube-embed
        {
            height: 700px;
        }
    }

    .testimonial
    {
        width: 100%;
        position: relative;
        padding: 4rem 0;

        background-image: url(../images/bg-testimonials-plane-body.jpg);
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;

        h4
        {
            margin: 0 0 1rem 0;
        }
    }

    .testimonial:before
    {
        position: absolute;
        content: ' ';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        background-color: transparentize($color-white, 0.3);
    }

    .filter
    {
        width: 100%;
        position: relative;
        padding: 6rem 0;

        background-image: url(../images/bg-testimonials-plane-body.jpg);
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;

        color: $color-white;

        h1
        {
            position:relative;
            z-index: 2000;

            color: $color-white;
            text-align: center;
            text-transform: uppercase;
        }

        .container
        {
            position: relative;
            z-index: 5000;
        }

        label
        {
            font-family: $font-heading;
            font-style: italic;
            font-size: 1.2rem;
        }

        .select-wrapper
        {
            background-color: $color-white;
        }

        .form-group
        {
            margin: 0;
        }

        .form-control
        {
            margin: 0;

            border-radius: 0 !important;
            border: none;

            color: $color-red;
        }
    }

    .filter:before
    {
        position: absolute;
        content: ' ';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        background-color: transparentize($color-black, 0.3);
    }

    .filter-sticky
    {
        background-color: transparentize($color-grey-mid, 0.9);

        h4
        {
            display: block;
            width: 100%;
            padding: 0.25rem 0;

            background-color: $color-red;

            color: $color-white;
            text-align: center;
            font-size: 1.5rem;
        }

		form
        {
            padding: 0 1.5rem 1.5rem 1.5rem;
        }

        label
        {
            font-family: $font-heading;
            font-style: italic;
            font-size: 1.2rem;
        }

        .form-group
        {
            margin: 0;
        }

        .form-control
        {
            margin: 0;

            border-radius: 0 !important;

            color: $color-red;
        }
    }

    .gallery-widget
    {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;

        a
        {
            overflow: hidden;
            flex-basis: 20%;
            position: relative;

            img
            {
                z-index: 1;
                overflow: hidden;

                $gallery-speed: 0.2s;
                -webkit-transition: all $gallery-speed ease-in-out;
                -o-transition: all $gallery-speed ease-in-out;
                transition: all $gallery-speed ease-in-out;

                &:hover
                {
                    $gallery-scale: 1.1;
                    -webkit-transform: scale($gallery-scale);
                    -moz-transform: scale($gallery-scale);
                    -ms-transform: scale($gallery-scale);
                    -o-transform: scale($gallery-scale);
                    transform: scale($gallery-scale);
                }

            }

            .btn
            {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 10;
            }
        }
    }

    .listing
    {
        margin-bottom: 2.5rem;
        display: flex;
        flex: 1 0 0%;
        flex-wrap: wrap;
        flex-direction: column;

        .thumbnail
        {
            width: 100%;
            position: relative;
            display: block;
            align-self: flex-start;

            h3
            {
                position: absolute;
                margin: 0;
                padding: 0.33rem;
                bottom: 0;
                left: 0;
                width: 100%;

                background-color: transparentize($color-black, 0.3);
                -webkit-transition: all 150ms ease-in-out;
                -moz-transition: all 150ms ease-in-out;
                -ms-transition: all 150ms ease-in-out;
                -o-transition: all 150ms ease-in-out;
                transition: all 150ms ease-in-out;

                font-size: 1.1rem;
                color: $color-white;
                font-weight: 400;
                text-transform: uppercase;

                small
                {
                    font-weight: 300;
                    font-style: normal;
                    float: right;
                    margin-top: 0.1rem;

                    text-align: right;
                    text-decoration: underline;
                    font-family: $font-default;
                    font-size: 0.8rem;
                    text-transform: capitalize;
                }
            }
        }

        .thumbnail:hover h3
        {
            background-color: $color-red;
        }

        .details
        {
            padding: 1rem 0;
            width: 100%;
            flex: 1 1 auto;

            font-size: 0.9rem;
        }

        .listing-footer
        {
            width: 100%;
            align-self: flex-end;
        }

        .price
        {
            width: 100%;
            padding: 0.25rem 0 1.25rem 0;
            border-top: 1px solid $color-grey-dark;

            font-size: 2rem;
            line-height: 1.5rem;

            small
            {
                font-size: 1rem;
            }
        }

        .actions
        {
            width: 100%;

            .btn-secondary
            {
                border-right: 1px solid $color-white;
            }

            .btn-primary
            {
                border-left: 1px solid $color-white;
            }
        }
    }

    .listing-aircraft
    {
        background-color: transparentize($color-white, 0.5);

        .details
        {
            padding-left: 1rem;
            padding-right: 1rem;

            color: $color-grey-dark;

            p
            {
                margin: 0;
            }

            h5
            {
                margin: 1rem 0 0.5rem 0;

                font-size: 1.1rem;
                color: $color-grey-dark;
            }
        }
    }

    .adrenaline-rating
    {
        width: 100%;
        position: relative;
        padding-bottom: 1.5rem;

        .bar
        {
            width: 100%;
            height: 10px;
            position: relative;

            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fea700+0,c51b1b+100 */
            background: #fea700; /* Old browsers */
            background: -moz-linear-gradient(left, #fea700 0%, #c51b1b 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, #fea700 0%,#c51b1b 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, #fea700 0%,#c51b1b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fea700', endColorstr='#c51b1b',GradientType=1 ); /* IE6-9 */

            .marker
            {
                width: 10px;
                height: 100%;
                position: absolute;

                background-color: $color-white;
            }
        }

        span
        {
            position: absolute;

            font-size: 0.8;
            font-family: $font-heading;
            font-style: italic;
        }
    }

    .listing-concierge
    {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        position: relative;

        background-color: $color-black;

        a
        {
            img
            {
                max-width: 90%;
            }
        }
    }

    .gift-certificate
    {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        z-index: 1;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        a
        {
            text-align: right;

            img
            {
                max-width: 100%;
            }
        }
    }

        .gift-certificate:before
        {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;

            background-color: transparentize($color-black, 0.5);
        }

    .homepage
    {
        display: flex;
        width: 100%;
        align-items: stretch;
        flex-wrap: wrap;

        .content
        {
            flex-basis: 50%;
            padding: 4rem;

            background-color: $color-grey-dark;

            color: $color-white;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            a
            {
                color: $color-white;
            }

            h1
            {
                font-size: 2.4rem;
            }
        }

        .feature-image
        {
            flex-basis: 50%;

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            .swiper-container
            {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }

    .filter-homepage
    {
        padding: 3rem 0 15rem;

        h2
        {
             margin-top: 0;

            text-transform: uppercase;
            color: $color-white;
        }
    }

    .fleet
    {
        width: 100%;
        padding: 3rem 0;

        background-image: url(../images/bg-fleet.jpg);
        background-repeat: no-repeat;
        background-size: cover;

        color: $color-white;

        h2
        {
            margin-top: 0;

            color: $color-white;
        }
    }

    .feature-box
    {
        position: relative;
        margin-top: 30px;
        margin-bottom: 20px;
        padding-left: 40px;

        .fbox-icon {
            display: block;
            position: absolute;
            width: 32px;
            top: -10px;
            left: 0;
            border: none !important;
            height: auto !important;

            i,
            em
            {
                border: none !important;
                background-color: transparent !important;
                color: $color-red;
                border-radius: 0;
                font-size: 32px;
            }
        }

        h3
        {
            margin: 5px 0 12px 0;
            text-transform: uppercase;
            font-size: 1.8rem;
            color: $color-black;
            font-style: italic;
            line-height: 0.9em;
        }

        p
        {
            margin-left: -40px;
            line-height: 1.2em;
        }
    }

    .grecaptcha-badge
    {
        z-index: 10;
    }

    /* Large desktop */
    @media (min-width: 1200px) {
        .subpage-banner
        {
            h1
            {
                transform: translateY(0);
            }
        }

        .filter-header
        {
            padding-top: calc(5rem + 80px);
        }
    }

    /* Desktop */
    @media (min-width: 992px) {

        .filter-sticky-top {
            position: sticky;
            top: 0;
            width: 100%;
            background-color: #ffffff;
            z-index: 1020;
        }
    }

    /* Desktop */
    @media (min-width: 992px) and (max-width: 1199px) {
        .concierge {
            font-size: 1.2rem;
        }

        .homepage {
            .content {
                padding: 2.5rem;
            }
        }

        .filter-homepage
        {

        }
    }

    /* Portrait tablet to landscape and desktop */
    @media (min-width: 768px) and (max-width: 991px) {
        .concierge
        {
            font-size: 1.1rem;
        }

        .filter-header
        {
            padding: 3rem 0 5rem 0;
        }

        .gallery-widget
        {
            a
            {
                flex-basis: 33.33%;
                position: relative;
            }
        }

        .listing
        {
            .actions
            {
                .btn
                {
                    border: none;
                    margin: 0.1rem 0;
                }
            }
        }

        .filter-homepage
        {
            padding: 3rem 0;

            h2
            {
                font-size: 2rem;
            }
        }

        .homepage {
            .content {
                padding: 2.5rem;
                width: 100%;
                flex-basis: 100%;
            }

            .feature-image
            {
                position: relative;
                width: 100%;
                flex-basis: 100%;
                height: 300px;
            }
        }
    }

    /* Landscape phone to portrait tablet */
    @media (max-width: 767px) {
        .page
        {
            padding: 1rem 0.8rem;
        }

        .subpage-banner
        {
            h1
            {
                font-size: 2rem;
            }
        }

        .filter-header
        {
            padding: 1.5rem 0.5rem;
        }

        .concierge
        {
            padding: 1rem 0.5rem;

            font-size: 1rem;

            .row
            {
                justify-content: center;
            }

            .btn-primary
            {
            }

            .container .row .col-lg-2
            {
                padding: 0;
            }

            img
            {
                position: relative;
                max-width: 130px;
                bottom: 0;
                left: 0;
            }
        }

        .gallery-widget
        {
            a
            {
                flex-basis: 50%;
                position: relative;
            }
        }

        .listing
        {
            .actions
            {
                .btn
                {
                    border: none;
                    margin: 0.1rem 0;
                }
            }
        }

        .listing-concierge
        {
            a
            {
                padding-top: 1rem;
            }
        }

        .filter-homepage
        {
            padding: 2rem 0.5rem;

            h2
            {
                font-size: 2rem;
            }
        }

        .homepage {
            .content {
                padding: 1.75rem;
                width: 100%;
                flex-basis: 100%;
            }

            .feature-image
            {
                width: 100%;
                flex-basis: 100%;
                height: 200px;
            }
        }
    }

/*=========================================================================*/

    /* [Video Styles] */

    .banner-video
    {
        height: 100vh;
        width: 100%;
        position: relative;
        overflow: hidden;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        .message-overlay
        {
            position: absolute;
            bottom: 10vh;
            left: 8vw;

            color: $color-white;

            h3
            {
                margin-top: 0;

                color: $color-white;
                text-transform: uppercase;
                text-shadow: 1px 1px 1px rgba(0,0,0, 0.75);
            }
        }

        .fas
        {
            position: absolute;
            bottom: 3vh;
            left: 50%;
            transform: translateX(-50%);

            color: $color-white;
        }
    }

    .video-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        //z-index: -1;
    }

    .video-foreground,
    .video-foreground iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    @media (min-aspect-ratio: 16/9) {
        .video-foreground {
            height: 300%;
            top: -100%;
        }
    }

    @media (max-aspect-ratio: 16/9) {
        .video-foreground {
            width: 300%;
            left: -100%;
        }
    }

    /* Landscape phone to portrait tablet */
    @media (max-width: 767px) {
        .banner-video
        {
            height: 300px;

            background-image: url(../images/video-placeholder.jpg);
            position: relative;

            .message-overlay
            {
                width: calc(100% - 16vw);
                h3
                {
                    font-size: 1.3rem;
                }
            }

            &:before
            {
                position: absolute;
                content: ' ';
                width: 100%;
                height: 100%;
                background: rgba(black,0.5);
            }
        }
    }

    /*=========================================================================*/

    /* [Footer Template Styles] */

    .footer
    {
        width: 100%;
        padding: 5rem 0 16rem 0;
        position: relative;
        z-index: 1;

        background-image: url(../images/bg-footer-fighter-planes.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;

        color: $color-white;
        font-size: 0.8rem;

        a
        {
            color: $color-white;
        }

        h6
        {
            margin: 0 0 0.3rem 0;
            padding: 0 0 0.3rem 0;

            border-bottom: 1px solid $color-white;

            color: $color-white;
            font-size: 1rem;
        }

        nav
        {
            margin-bottom: 1.5rem;

            .nav-link
            {
                padding: 0.5rem 0;

                border-bottom: 1px solid $color-white;

                text-transform: uppercase;
            }

            .nav-link:hover
            {
                background-color: $color-red;
            }
        }

        .social
        {
            font-size: 2rem;
        }
    }

    .footer:after
    {
        position: absolute;
        content: ' ';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        background-color: transparentize($color-black, 0.3);
    }

    .footnote
    {
        width: 100%;
        padding: 1rem 0.5rem;

        background-color: $color-grey-dark;

        color: $color-white;
        text-align: center;
        font-size: 0.9rem;
        text-transform: uppercase;

        a
        {
            color: $color-white;
        }
    }

    /* Landscape phone to portrait tablet */
    @media (max-width: 767px) {
        .footer
        {
            padding: 2rem 0.5rem;

            img
            {
                margin-top: 2rem;
            }
        }
    }

    /*=========================================================================*/

    /* [Table as row override Styles] */

    table.table-as-row > tbody > tr:before,
    table.table-as-row > tbody > tr:after {
        content: " ";
        display: table;
        clear: both;
    }

    table.table-as-row > tbody > tr {
        height: auto !important;
    }

    table.table-as-row,
    table.table-as-row > tbody,
    table.table-as-row > tbody > tr {
        display: block;
        width: auto;
    }

    table.table-as-row > tbody > tr {
        display: block;
        width: auto;
        margin-right: -15px;
        margin-left: -15px;
    }

    table.table-as-row > tbody > tr > td[class*=col-] {
        display: block;
        height: auto !important;
        margin-bottom: 20px;
    }

    /* col-lg */
    @media (min-width: 1200px) {
        table.table-as-row > tbody > tr > td[class*=col-lg-] {
            float: left;
        }
    }

    /* col-md */
    @media (min-width: 992px) {
        table.table-as-row > tbody > tr > td[class*=col-md-] {
            float: left;
        }
    }

    /* col-sm */
    @media (min-width: 768px) {
        table.table-as-row > tbody > tr > td[class*=col-sm-] {
            float: left;
        }
    }

    /* col-xs */
    table.table-as-row > tbody > tr > td[class*=col-xs-] {
        float: left;
    }
